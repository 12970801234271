<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="상생할인전" />
				</div>
			</div>
		</div>
		<div id="exhibition" class="page-content">
			<div class="container">
				<button type="button" class="exhibit-banner">
					<div class="exhibit-banner-left">
						<div class="exhibit-banner-img">
							<img src="@/assets/img/exhibition/icon_noticebell.png" alt="배너 아이콘" />
						</div>
						<div class="exhibit-banner-desc">
							<span>동행축제 채널과 <span class="clr-yellow">카카오톡 친구 맺고</span></span>
							<span class="bold-txt">관련 소식을 가장 빠르게 받아보세요</span>
						</div>
					</div>
					<div class="exhibit-banner-button hidden-mobile">
						<span class="banner-text">클릭하고 친구 추가</span>
						<i class="banner-icon"><icon name="icon-arrow-r" /></i>
					</div>
				</button>
				<div class="exhibit-category">
					<ul class="exhibit-category-list">
						<li v-for="(btnItem, idx) in btnList" :key="idx" class="exhibit-category-button">
							<div :class="idx == 1 ? 'exhibit-category-item is-active' : 'exhibit-category-item'">
								<!-- <div class="exhibit-category-item is-active"> -->
								<div class="button-icon">
									<img :src="require(`@/assets/img/exhibition/${btnItem.btnIcon}`)" :alt="btnItem.btnTitle" />
								</div>
								<span class="button-title">{{ btnItem.btnTitle }}</span>
							</div>
						</li>
					</ul>
				</div>
				<div class="exhibit-item-container">
					<ul class="exhibit-item-list">
						<li class="exhibit-item-li" v-for="item in items" :key="item.id">
							<exhibition-item :item="item" />
						</li>
					</ul>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
import ExhibitionItem from '@/components/content/ExhibitionItem';
import Icon from '@/components/common/Icon';

export default {
	name: 'Exhibition',
	components: { PageHeader, ExhibitionItem, Icon },
	data: () => ({
		btnList: [
			{
				btnIcon: 'icon_check.png',
				btnTitle: '전체보기',
			},
			{
				btnIcon: 'btn_img01.png',
				btnTitle: '오픈마켓',
			},
			{
				btnIcon: 'btn_img02.png',
				btnTitle: '종합몰',
			},
			{
				btnIcon: 'btn_img03.png',
				btnTitle: '펀딩/공동구매',
			},
			{
				btnIcon: 'btn_img04.png',
				btnTitle: '식품전문',
			},
			{
				btnIcon: 'btn_img05.png',
				btnTitle: '패션/기타',
			},
			{
				btnIcon: 'btn_img06.png',
				btnTitle: '해외쇼핑몰',
			},
		],
		items: [
			{
				id: 0,
				title: '특가딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_gmarket.png',
				brand: '지마켓',
				desc: '10만원 상당 경품 이벤트',
				closed: false,
			},
			{
				id: 1,
				title: '타임딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_lotte.png',
				brand: '롯데온',
				desc: '타임딜(종료)',
				closed: true,
			},
			{
				id: 2,
				title: 'SKT 고객 대상 기획전',
				discount: '30%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_tdeal.png',
				brand: 'T딜',
				desc: 'SKT 고객 대상 기획전',
				closed: false,
			},
			{
				id: 3,
				title: '특가딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_gmarket.png',
				brand: '지마켓',
				desc: '10만원 상당 경품 이벤트',
				closed: false,
			},
			{
				id: 4,
				title: '타임딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_lotte.png',
				brand: '롯데온',
				desc: '타임딜',
				closed: false,
			},
			{
				id: 5,
				title: 'SKT 고객 대상 기획전',
				discount: '30%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_tdeal.png',
				brand: 'T딜',
				desc: 'SKT 고객 대상 기획전',
				closed: false,
			},
			{
				id: 6,
				title: '특가딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_gmarket.png',
				brand: '지마켓',
				desc: '10만원 상당 경품 이벤트',
				closed: false,
			},
			{
				id: 7,
				title: '타임딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_lotte.png',
				brand: '롯데온',
				desc: '타임딜',
				closed: false,
			},
			{
				id: 8,
				title: 'SKT 고객 대상 기획전',
				discount: '30%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_tdeal.png',
				brand: 'T딜',
				desc: 'SKT 고객 대상 기획전',
				closed: false,
			},
			{
				id: 9,
				title: '특가딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_gmarket.png',
				brand: '지마켓',
				desc: '10만원 상당 경품 이벤트',
				closed: false,
			},
			{
				id: 10,
				title: '타임딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_lotte.png',
				brand: '롯데온',
				desc: '타임딜',
				closed: false,
			},
			{
				id: 11,
				title: 'SKT 고객 대상 기획전',
				discount: '30%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_tdeal.png',
				brand: 'T딜',
				desc: 'SKT 고객 대상 기획전',
				closed: false,
			},
			{
				id: 12,
				title: '특가딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_gmarket.png',
				brand: '지마켓',
				desc: '10만원 상당 경품 이벤트',
				closed: false,
			},
			{
				id: 13,
				title: '타임딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_lotte.png',
				brand: '롯데온',
				desc: '타임딜',
				closed: false,
			},
			{
				id: 14,
				title: 'SKT 고객 대상 기획전',
				discount: '30%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_tdeal.png',
				brand: 'T딜',
				desc: 'SKT 고객 대상 기획전',
				closed: false,
			},
			{
				id: 15,
				title: '특가딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_gmarket.png',
				brand: '지마켓',
				desc: '10만원 상당 경품 이벤트',
				closed: false,
			},
			{
				id: 16,
				title: '타임딜',
				discount: '20%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_lotte.png',
				brand: '롯데온',
				desc: '타임딜',
				closed: false,
			},
			{
				id: 17,
				title: 'SKT 고객 대상 기획전',
				discount: '30%',
				period: '04.29 ~ 05.26',
				logoImg: 'logo_tdeal.png',
				brand: 'T딜',
				desc: 'SKT 고객 대상 기획전',
				closed: false,
			},
		],
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>
