<template>
	<a href="#" target="_blank" class="exhibit-item-content" v-bind:class="{ closed: item.closed }">
		<div class="item-content">
			<div class="item-content-info">
				<div class="item-content-info-coupon">
					<div class="item-content-info-coupon-img">
						<img :src="require(`@/assets/img/exhibition/${item.logoImg}`)" alt="item.title" />
					</div>
					<div class="item-content-info-coupon-desc">
						<span class="subtext">최대 할인</span>
						<span class="cpndisc">{{ item.discount }}</span>
					</div>
				</div>
				<div class="item-content-info-title">{{ item.title }}</div>
				<div class="item-content-info-date">{{ item.period }}</div>
			</div>
			<div class="item-content-sub">
				<span class="subtitle">{{ item.brand }}</span>
				<span class="subtext">{{ item.desc }}</span>
			</div>
		</div>
	</a>
</template>

<script>
export default {
	name: 'ExhibitionItem',
	components: {},
	props: {
		item: null,
	},
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>
